<template>
  <BasicButton
    :disabled="disabled"
    :label="t('shop.cart.costSummary.checkoutLink')"
    :type="EButtonType.SUBMIT"
    :btn-style="EButtonStyle.CTA"
    :testid="testid"
    full-width
    @click="handleClick"
  ></BasicButton>
</template>

<script setup lang="ts">
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import { BasicButton } from '@/complib';
import { useSessionStore } from '~/stores/useSessionStore';

const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  testid: {
    type: String,
    required: false,
    default: '',
  },
});

const { t } = useTrans();
const session = useSessionStore();
const router = useRouter();
const emit = defineEmits(['click']);

function handleClick() {
  emit('click');
  if (!props.disabled) {
    session.isLoggedIn
      ? router.push('/checkout/review')
      : router.push('/checkout/login');
  }
}
</script>
